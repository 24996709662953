import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/System/User/SuccessBox';
import DangerBox from 'components/Web_User_Interface/1080p_Series/System/User/DangerBox';
import UserTable from 'components/Web_User_Interface/1080p_Series/System/User/userTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // User Management",
  "path": "/Web_User_Interface/1080p_Series/System/User/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Your camera will be set up with 3 user classes - administrator, user and visitor - and one password for all of them - instar. Please change this as soon as you want to forward your camera to the internet. And don´t forget to change all three of them. You can use the RTSP Stream if you want to allow users to access your camera without the hassle of a password.",
  "image": "./WebUI_1080p_SearchThumb_System_User.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_System_User.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menü // Benutzerverwaltung' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Die Weboberfläche Ihrer Kamera bietet Ihnen eine dreistufige Benutzerverwaltung für Benutzer mit unterschiedlichen Zugriffsrechten für Administratoren, Benutzer und Besucher. Sie können die Benutzernamen und zugehörige Kennwörter für alle 3 Stufen im Benutzer-Overlay festlegen. Gäste [guest] haben nur Zugriff auf den Videostream der Kamera, Benutzer [user] können die Kamera zusätzlich noch schwenken und nur der Administrator [administrator] hat Zugriff auf die Konfigurationsmenüs der Kamera.' image='/images/Search/WebUI_1080p_SearchThumb_System_User.png' twitter='/images/Search/WebUI_1080p_SearchThumb_System_User.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Series/System/User/' locationFR='/fr/Web_User_Interface/1080p_Series/System/User/' crumbLabel="User Management" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1ad85854db0ad7b25019f69d632080da/bb27a/1080p_Settings_System_User.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACzElEQVQoz2WSW0hUQRjHz0MkJGSRFCmW7qqZRlGIK2pmgmjXB80SylCsyDRBds+ZOZc9M3Pc1UzJtFxzH7qICF6gx+gl6GWjIKILPRQ99Fgh697c3TN7duKctXroxzAPA7/5f983wwkCDywkSeadg67RR86Hbwf9gf6ZQO90oM8X6Jt51esLDPhf82RSBkAR5Qvt5232koaGRs4lAB5AACHG+ED5/iHPiM7Yz2BsNaKHE+lwksUo+/Yr+eJLfME3gbu7nR2Xrp5qa610nK49askChBASggvtpUTzMsZSqZSRonoixtKUMSOdTjHGnvvv+AZueroldLajv97R0djM8QDwAhAhVNyooebwwpyfMUYp1SmNRKOJRDJFU5RSxti0Nq609Uzf6Jzsl4/VXDxe32QmCwCKEKqIHKkonhwbzsiGYcTj8WQySc2bdN1I35NdL1HrG9Hx8e7lp6BHamvkBAD/lE0KC+2ygjJl67q+uroaCoUikUg4HI6tJ+Zmhs81HGqpKHOfzH/WuWPleq0puwRzYoSQoiIbwpgxZiZZyX9hjEka3rlr776S8pztu0tzs4Wmcg6KIg+gAIAp2+yqaiYbhkEpDQaDkUg0Go2FQ6E0pVe6evbszrMXl2zL2ZqbvandUWwmO3lBEEy5oKBAURTGWDKR0HU9uLamr0cMfT1mpNKMdfVdy7Pl5xXlZ23ZnMVxLQcLOAChkHlnhKqqqqamJjM9M4uvP/T339ej7z4Ynz6P+G6f6D7jaK6rrK4sLiutrquzBgagaIExnp19sLKysri4uLS0tLy8POGfv3X/yeOR0fmR0fGxcYKw12Pi9Xq8niEzeUMVRYSQoiiZA2iB3RJBsqwRSSOqqmKMEULWZsJJkiiJ5hJFUVVVQjRt6B+YaJhoGsYaxoQQbJExMcacize/p5M3K1AUt6y4FcXtVjfA/4EQUlVVsfgNLbXgkOg48JQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ad85854db0ad7b25019f69d632080da/e4706/1080p_Settings_System_User.avif 230w", "/en/static/1ad85854db0ad7b25019f69d632080da/d1af7/1080p_Settings_System_User.avif 460w", "/en/static/1ad85854db0ad7b25019f69d632080da/7f308/1080p_Settings_System_User.avif 920w", "/en/static/1ad85854db0ad7b25019f69d632080da/b0250/1080p_Settings_System_User.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1ad85854db0ad7b25019f69d632080da/a0b58/1080p_Settings_System_User.webp 230w", "/en/static/1ad85854db0ad7b25019f69d632080da/bc10c/1080p_Settings_System_User.webp 460w", "/en/static/1ad85854db0ad7b25019f69d632080da/966d8/1080p_Settings_System_User.webp 920w", "/en/static/1ad85854db0ad7b25019f69d632080da/f16bf/1080p_Settings_System_User.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ad85854db0ad7b25019f69d632080da/81c8e/1080p_Settings_System_User.png 230w", "/en/static/1ad85854db0ad7b25019f69d632080da/08a84/1080p_Settings_System_User.png 460w", "/en/static/1ad85854db0ad7b25019f69d632080da/c0255/1080p_Settings_System_User.png 920w", "/en/static/1ad85854db0ad7b25019f69d632080da/bb27a/1080p_Settings_System_User.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1ad85854db0ad7b25019f69d632080da/c0255/1080p_Settings_System_User.png",
              "alt": "Web User Interface - 1080p Series - System User",
              "title": "Web User Interface - 1080p Series - System User",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <UserTable mdxType="UserTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The web user interface offers a 3-level user management with different access rights for administrators, operators and visitors. You can set user names and passwords for all three user levels in the Users mask. Guests will only have access to the video stream of the camera, Users in addition are able to use the pan & tilt function and only the Administrator will have access to all the camera's configuration menus.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      